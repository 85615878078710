<template>
  <div>
      <listaPlagas :items="items"/>            
  </div>
</template>


<script>
import PlagasService from "@/services/PlagasService";
import listaPlagas from '@/components/listaPlagas.vue';

export default {
   components: { listaPlagas },
  name: "cultivos",
  props: {
    msg: String,
  },
  data: () => ({
    selected: [],
    items: [],
    reveal: false,
  }),
  mounted() {
    this.$Progress.start();
    this.traerdata();
    //console.log('items :>> ', this.items);
  },
  methods: {
    traerdata() {
      const plagas_service = new PlagasService();
      plagas_service.getPlagas("plagas")
      .then((result) => {        
        this.$Progress.finish();          
        result.forEach((doc) => {          
          this.items.push(doc.data())
          //console.log(doc.data());
          //console.log('doc.id :>> ', doc.id);
        });

      })
      .catch((err) => {
        this.$Progress.fail();
        console.log("object :>> ", err);
      })  

    },
    async crear_plagas() {
      this.$Progress.start();
      try {       
        
            // const plagas_service = new PlagasService();       
        //  hrc.forEach(element => {        
        //     const plagas = {
        //       nombre: element.nombre, 
        //       detalle: element.detalle, 
        //       fotos: element.fotos         
        //     };             
        //     plagas_service.getCrearPlagas(plagas, "plagas");           
        //    //console.log('cultivo :>> ', cultivo);
        //  });

        this.$Progress.finish();    

        // product_service.getCrearProd(prod, key);
        
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>

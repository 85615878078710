<template>
  <v-container class="grey lighten-5">
    <img src="/assets/images/cultivo.png" width="50" /> 
    <v-row>
      <v-col
        v-for="item,n in items"
        :key="n"
        cols="6"
        md="4"
      >
 <!-- inicio card -->
<v-card
    class="mx-auto"
    max-width="344"    
    shaped
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-h9  mb-4">
         {{item.nombre}}
        </div>
        <v-list-item-title class="text-h7 mb-1">
         
          <v-img  max-height="103" :src="`assets/images/${item.fotos[0]}`"></v-img>
        </v-list-item-title>
        <v-list-item-subtitle>{{item.tipo}}</v-list-item-subtitle>
      </v-list-item-content>


    </v-list-item>
   

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
        small
         @click="abrir_ventana(item)"
      >
        Detalle
      </v-btn>
    <v-btn
        outlined
        rounded
        text
        small   
        @click="ver_productos(item.nombre)"      
      >
        Productos
      </v-btn>      
    </v-card-actions>
  </v-card> 
 <!-- fin  card -->
      </v-col>    
    </v-row>
<!-- ventana emergente  detalle   -->
  <v-row justify="center">

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>            
        <v-card-title class="text-h5 light-green lighten-1">
          <div >Descripción de {{detalle.nombre}} </div>   
        </v-card-title>

        <v-card-text class="text-justify">
          <div class="text-subtitle-2">{{detalle.detalle}} </div>         
             
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>   

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<!-- fin  ventana emergente     -->

<!-- ventana emergente  productos   -->
  <v-row justify="center">

    <v-dialog
      v-model="dialog2"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>            
        <v-card-title class="text-h5 light-green lighten-1">
          <div >Lista de Productos </div>   
        </v-card-title>

        <v-card-text class="text-justify">
          <div class="text-subtitle-2" v-for="(prod,key) in selected" :key="key">            
            <v-btn text :to="`/prod/${prod.key}`">
              <v-icon>mdi-arrow-right-bold</v-icon>{{prod.nombre}}
            </v-btn>

            </div>         
             
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>   

          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<!-- fin  ventana emergente     -->
  </v-container>
</template>

<script>
  export default {
  name: "listaProductos",
  props: {
    items: Array,
  },
  data: () => ({    
    detalle: {},
    dialog:false,
    dialog2:false,
    reveal: false,
    selected: [],
 cultivo: [
        {
            "producto": "ACTARA 25 WG",
            "cultivo": "ROSA"
        },
        {
            "producto": "ACTARA 25 WG",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "ACTELLIC  50 EC",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "ACTELLIC  50 EC",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "ACTELLIC  50 EC",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "ALTIMA 500 SC",
            "cultivo": "ROSA"
        },
        {
            "producto": "ALTIMA 500 SC",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "ALTO 100 SL",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "AMISTAR 50 WG",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "AMISTAR 50 WG",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "AMISTAR 50 WG",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "AMISTAR TOP SC",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "AMISTAR TOP SC",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "AMISTAR TOP SC",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "AMISTAR TOP SC",
            "cultivo": "ROSA"
        },
        {
            "producto": "DACONIL 720 SC",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "ENGEO 247 SC",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "ENGEO 247 SC",
            "cultivo": "ALSTROEMERIA"
        },
        {
            "producto": "ENGEO 247 SC",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "ENGEO 247 SC",
            "cultivo": "GERBERA"
        },
        {
            "producto": "ENGEO 247 SC",
            "cultivo": "ROSA"
        },
        {
            "producto": "ENGEO 247 SC",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "HICURE",
            "cultivo": "ROSA"
        },
        {
            "producto": "KARATE ZEON 5 CS",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "KARATE ZEON 5 CS",
            "cultivo": "ROSA"
        },
        {
            "producto": "MAINSPRING FLORA",
            "cultivo": "ROSA"
        },
        {
            "producto": "MAINSPRING FLORA",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "MATCH 50 EC",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "MERTECT 500 SC",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "MERTECT 500 SC",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "PICATINA FLORA",
            "cultivo": "ROSA"
        },
        {
            "producto": "PLENTRIX",
            "cultivo": "ROSA"
        },
        {
            "producto": "POLO 500 SC",
            "cultivo": "ROSA"
        },
        {
            "producto": "REVUS 250 EC",
            "cultivo": "ROSA"
        },
        {
            "producto": "RIDOMIL GOLD 68 WP",
            "cultivo": "ROSA"
        },
        {
            "producto": "SCORE 250 EC",
            "cultivo": "ROSA"
        },
        {
            "producto": "SCORE 250 EC",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "SEGOVIS FLORA",
            "cultivo": "ROSA"
        },
        {
            "producto": "SOLVIT",
            "cultivo": "ROSA"
        },
        {
            "producto": "SOLVIT",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "SOLVIT",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "SOLVIT",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "SUNJET",
            "cultivo": "ROSA"
        },
        {
            "producto": "SUNJET",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "SUNJET",
            "cultivo": "CLAVEL"
        },
        {
            "producto": "SWITCH 62.5 WG",
            "cultivo": "ROSA"
        },
        {
            "producto": "SWITCH 62.5 WG",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "TRIGARD 75 WP",
            "cultivo": "ORNAMENTALES"
        },
        {
            "producto": "TRIGARD 75 WP",
            "cultivo": "CRISANTEMO"
        },
        {
            "producto": "VERTIMEC 018 EC X LT",
            "cultivo": "ROSA"
        },
        {
            "producto": "VERTIMEC 018 EC X LT",
            "cultivo": "CRISANTEMO"
        }
    ]    
  }),    
  methods:{
    abrir_ventana(datos){      
      this.detalle = datos
      this.dialog = true
      
    },
    ver_productos(nombre){
      this.selected = [];
      this.cultivo.forEach((doc) =>{
        if(doc.cultivo == nombre){
         const nombre = doc.producto;
         const key = nombre.replace(/(\s)+/gi, "-").toLowerCase();          
          let obj ={
            nombre: nombre,
            key: key
          }

          this.selected.push(obj)
        }
      })
      this.dialog2 = true
      //console.log(`this.selected`, this.selected)
    }    
  }  

  }
</script>

<style lang="scss" scoped>

</style>        
import { render, staticRenderFns } from "./listaPlagas.vue?vue&type=template&id=9796ab52&scoped=true&"
import script from "./listaPlagas.vue?vue&type=script&lang=js&"
export * from "./listaPlagas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9796ab52",
  null
  
)

export default component.exports